import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"


const Greenfox = () => {
    const data = useStaticQuery(graphql`
    {
        logo_greenfox: file(relativePath: { eq: "greenfox-logo.png" }) {
            childImageSharp {
                fixed(width: 128) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
    `);

    return <div className="flex items-center lg:flex-row flex-col">
    <Img className="inline-block mr-4" fixed={data.logo_greenfox.childImageSharp.fixed} />
    <p className="font-bold">Samen met GreenFox Social Return verzorgen wij de beste stucadoors</p>
    </div>
}

Greenfox.propTypes = {

}

Greenfox.defaultProps = {

}

export default Greenfox

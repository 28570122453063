import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Greenfox from "../components/greenfox"

const AboutUsPage = ({data}) => (
  <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
    <SEO title="Over de opleiding" />
    <h1>Over de opleiding</h1>
    <p>Wij streven naar maatwerk. Na afname van een uitgebreide intake maken wij een inschatting van wat een aankomend stucadoor nodig heeft om zichzelf stucadoor te mogen noemen. Afhankelijk van wat een leerling wil en kan stellen wij een plan van aanpak op.</p>
    
    <p>Regelmatig vinden er voortgangsgesprekken plaats en zo nodig, wordt het plan van aanpak bijgesteld. Zodra de aankomend stukadoor zijn diploma of certificaat heeft behaald, wordt hij voorgesteld aan een passende werkgever. Daarnaast bieden we de mogelijkheid om een vervolgopleiding te volgen op MBO 2 niveau.</p>

    <p>Wij hebben een groot netwerk, zodat uitstroom naar werk bijna voor 100% gegarandeerd is.</p>

    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 text-center">
      <Img className="w-full" fluid={data.files.edges[0].node.childImageSharp.fluid} />
      <Img className="w-full" fluid={data.files.edges[1].node.childImageSharp.fluid} />
      <Img className="w-full hidden lg:block" fluid={data.files.edges[2].node.childImageSharp.fluid} />
    </div>

    <h2>Wat leren wij de jongeren?</h2>
    <p>Traub stuc maakt gebruik van werkpleksimulatie. In boxen wordt de praktijksituatie zoveel mogelijk nagebootst. De aankomende stukadoors krijgen praktijkopdrachten die zij in de boxen mogen uitvoeren. Na het uitvoeren van de opdracht wordt er met de leermeester bekeken in hoeverre de opdracht is begrepen en in hoeverre de opdracht correct is uitgevoerd. Zo worden de leerlingen voorbereid op het praktijkexamen.</p>

    <p>Traub stuc vindt veilig werken belangrijk, wij werken altijd met: werkschoenen, werkkleding en veilige materialen.</p>
    
    <p>Bij Traub Stuc werken wij altijd met a-merk materialen en gereedschappen. Wij vinden het belangrijk dat hier economisch en zuinig mee om wordt gegaan.</p>
    
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 text-center">
      <Img className="w-full" fluid={data.files.edges[3].node.childImageSharp.fluid} />
      <Img className="w-full" fluid={data.files.edges[4].node.childImageSharp.fluid} />
      <Img className="w-full hidden lg:block" fluid={data.files.edges[5].node.childImageSharp.fluid} />
    </div>

    <h2>Begeleiding na de opleiding door GreenFox Social Return</h2>
    <p>Bij Traub kunnen we van iedereen een stukadoor maken alleen daarna begint de uitdaging pas. Het eerste half jaar hebben onze jongens vaak nog wat begeleiding nodig en willen we dit niet neerleggen bij onze klanten. Daarom werken wij samen met <a href="https://socialreturn.nu" >GreenFox Social Return</a>. Zij begeleiden onze stukadoors het eerste half jaar bij jullie. Een echte jobcoach helpt ze met praktische zaken zoals eventuele schulden, vervoer, prive zaken, etc. </p>
    <p>
    Gedurende de eerste 6 maanden zullen ze gedetacheerd worden en zit een opdrachtgever nog nergens aan vast. Zo kan je iedereen een kans geven!
    </p>
    <Greenfox />


  </Layout>
)




export default AboutUsPage

export const query =  graphql`
{
  files: allFile(filter: {relativeDirectory: {eq: "about-us"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`